
import { computed, defineComponent, ref } from "vue";
import Entities from "@/components/base/common/Entities.vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import { Field } from "vee-validate";
import ContentMappingService from "@/core/services/ContentMappingService";
import Swal from "sweetalert2";

export default defineComponent({
  name: "List",
  components: {BaseSelect, BaseForm, BaseModal, KtDatatable, QuickAction, Entities, Field},
  setup() {
    const state = ref({
      submitting: false,
      model: {type: 'HELP_CENTER', key: ''},
      id: {},
    })

    setCurrentPageBreadcrumbsWithParams("Content Mapping",
      [
        {link: false, router: '', text: 'List'}
      ]
    )
    const page = computed(() => store.state.ContentMappingModule.page);
    const tableHeader = ref([
      {
        name: "Type ",
        key: "type",
        sortable: true,
      },
      {
        name: "Key",
        key: "key",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      page,
      ...LoadFilterObjects(Actions.LOAD_CONTENT_MAPPINGS, {}, []),
      tableHeader,
      state,
    }
  },
  methods: {
    onEdit(item) {
      this.state.model = {type: item.type, key: item.key}
      this.state.id = item.id;
      const baseModalRef = this.$refs.refContent as typeof BaseModal
      baseModalRef.showBaseModal()
    },

    addContent() {
      this.state.model = {type: 'HELP_CENTER', key: ''}
      this.state.id = '';
      const baseModalRef = this.$refs.refContent as typeof BaseModal
      baseModalRef.showBaseModal()
    },
    onClose() {
      const baseModalRef = this.$refs.refContent as typeof BaseModal
      baseModalRef.hideBaseModal()
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ContentMappingService.delete(id).then(() => {
            this.paginationLoad();
          })
        }
      })
    },
    onSave() {
      if (this.state.id) {
        ContentMappingService.update(this.state.id, this.state.model).then(() => {
          this.paginationLoad()
          this.onClose()
        }).finally(() => {
          this.state.submitting = false;
        })
      } else {
        ContentMappingService.create(this.state.model).then(() => {
          this.paginationLoad()
          this.onClose();
        }).finally(() => {
          this.state.submitting = false;
        })
      }


    }
  }
})
